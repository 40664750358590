import { Injectable } from '@angular/core';
import {Geolocation, PermissionStatus, Position} from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  constructor(
  ) { }

  public checkLocalisationStatus(callbackSuccess, callbackError) {
    Geolocation.checkPermissions().then( (permission : PermissionStatus) => {
        if (permission.coarseLocation === 'granted') {
              if (permission.location != 'granted') {
                  // the accuracy option will be ignored by iOS
                  Geolocation.requestPermissions().then(
                      (requestedPermission: PermissionStatus) => {
                          if (requestedPermission.location !== 'granted') {
                              if (callbackError !== null) {
                                  callbackError('not_authorized');
                              }
                          } else {
                              this.checkLocalisationStatus(callbackSuccess, callbackError);
                          }
                      },
                      (error) => (callbackError !== null) ? callbackError('high_accuracy_issue', error) : null
                  );
              } else {
                  callbackSuccess();
              }
        } else {
          Geolocation.requestPermissions().then( (requestedPermission: PermissionStatus) => {
              if (requestedPermission.coarseLocation !== 'granted') {
                  if (callbackError !== null) {
                      callbackError('not_authorized');
                  }
              } else {
                  this.checkLocalisationStatus(callbackSuccess, callbackError);
              }
          }, (error) => {
              if (callbackError !== null) {
                  callbackError('request_authorization_error');
              }
          });
        }
    }).catch((error) => {
        callbackError('request_authorization_error');
    })
  }

  public watchPosition() {
    this.checkLocalisationStatus(() => {
        Geolocation.watchPosition({enableHighAccuracy: true, timeout: 10000}, (position: Position) => {});
    }, null);
  }

  public getCurrentPosition(callbackSuccess, callbackError) {
    this.checkLocalisationStatus(() => {
      Geolocation.getCurrentPosition({enableHighAccuracy: true, timeout: 10000, maximumAge: 30000}).then((resp: Position) => {
          callbackSuccess(resp);
        }).catch((error) => {
            callbackError(error);
        });
    }, (error) => {
        callbackError(error);
    });
  }

  public getDistanceBetween(currentPosition = {latitude: null, longitude: null}, distantPosition = {latitude: null, longitude: null}) {
      const R = 6371e3; // metres
      const φ1 = this.convertToRadians(distantPosition.latitude);
      const φ2 = this.convertToRadians(currentPosition.latitude);
      const Δφ = this.convertToRadians(currentPosition.latitude - distantPosition.latitude);
      const Δλ = this.convertToRadians(currentPosition.longitude - distantPosition.longitude);

      const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c;
  }

    private convertToRadians(degrees) {
        // Doc - Converting degree to radians by extending Js Math : http://cwestblog.com/2012/11/12/javascript-degree-and-radian-conversion/
        return degrees * Math.PI / 180;
    }
}

