import {BookableAccessModel} from '../../../models/access/bookableAccess.model';

export class BookingForm
{
        isValidated = false;
        accessServiceId: number = null;
        selectedAccess: BookableAccessModel = null;
        from = '';
        to = '';
        allDay = false;
        allowByHour = false;
        minDateFrom = '';
        maxDateFrom = '';
        minDateTo = '';
        maxDateTo = '';
        allDayAllowed = false;
        disableTo = true;

        public reset()
        {
            this.isValidated = false;
            this.selectedAccess = null;
            this.from = '';
            this.to = '';
            this.allDay = false;
            this.allowByHour = false;
            this.minDateFrom = '';
            this.maxDateFrom = '';
            this.minDateTo = '';
            this.maxDateTo = '';
            this.allDayAllowed = false;
            this.disableTo = true;
        }
}
