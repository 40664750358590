import { Injectable } from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {StorageService} from '../../../services/storage.service';
import {DateService} from '../../../services/date.service';

@Injectable({
    providedIn: 'root'
})

export class BookingService {

    constructor(
        private api: ApiService,
        private http: HttpClient,
        private storageService: StorageService,
        private dateService: DateService
    ) { }

    public getBookings() {
        const organisation = this.storageService.getCurrentOrganisation();

        return this.http.get(
            this.api.getHostVersion() + '/organisation/' + organisation + '/access-service-beneficiaries/time-spot-slot?access_service_type=budget,community_booking',
            {headers: this.api.getHeaders()}
        );
    }

    public loadBookableAccesses(dateFrom) {
        const organisation = this.storageService.getCurrentOrganisation();

        let pivotDate = encodeURIComponent(this.dateService.formatToIso8601(dateFrom));

        if (this.dateService.format(dateFrom, 'yyyy-MM-dd') === this.dateService.format(new Date(), 'yyyy-MM-dd')) {
            pivotDate = encodeURIComponent(this.dateService.format(dateFrom, 'yyyy-MM-ddT') + this.dateService.format(new Date(), 'HH:mm:ssZ'));
        }

        return this.http.get(
            this.api.getHost() + '/mobile/access-control/organizations/' + organisation + '/bookable-accesses?strategies=budget,community_booking&pivotDate=' + pivotDate,
            {
                headers: this.api.getHeaders()
            }
        );
    }

    public getTimespanForAccessService(accessServiceId: number, pivotDate: Date) {
        const organisation = this.storageService.getCurrentOrganisation();

        pivotDate.setHours(0, 0, 0);
        let hourFormatted;

        if (this.dateService.isToday(pivotDate)) {
            hourFormatted = this.dateService.format(new Date(), 'HH:mm:ssZ'); // If we are today, use current time as basis
        } else {
            hourFormatted = this.dateService.format(pivotDate, 'HH:mm:ssZ'); // Otherwise use beginning of day
        }

        const from = encodeURIComponent(this.dateService.format(pivotDate, 'yyyy-MM-ddT') + hourFormatted);
        const to = encodeURIComponent(this.dateService.format(pivotDate, 'yyyy-MM-ddT23:59:59Z'));

        return this.http.get(
            this.api.getHost() + '/mobile/access-control/organizations/' + organisation + '/accesses/' + accessServiceId + '/availabilities?from=' + from + '&to=' + to,
            {
                headers: this.api.getHeaders(),
            }
        );
    }

    public postTimeSpotSLot(accessServiceId: number, fromDate: Date, toDate: Date) {
        const organisation = this.storageService.getCurrentOrganisation();

        return this.http.post(
            this.api.getHost() + '/mobile/access-control/organizations/' + organisation + '/accesses/' + accessServiceId + '/reservation',
            {
                from: this.dateService.formatToIso8601(fromDate),
                to: this.dateService.formatToIso8601(toDate)
            },
            {headers: this.api.getHeaders()}
        );
    }

    public deleteTimeSpotSlot(accessServiceId: number, timeSpotSlotId: number) {
        const organisation = this.storageService.getCurrentOrganisation();

        return this.http.delete(
            this.api.getHost() + '/mobile/access-control/organizations/' + organisation + '/accesses/' + accessServiceId + '/reservations/' + timeSpotSlotId,
            {headers: this.api.getHeaders()}
        );
    }
}
