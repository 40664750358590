import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../services/api.service';
import {ProfileModel} from '../../../../models/profile/profile.model';
import {StorageService} from '../../../services/storage.service';

@Injectable({
  providedIn: 'root'
})

export class AddPhoneService {

  constructor(
      private api: ApiService,
      private http: HttpClient,
      private storageService: StorageService,
  ) { }

 public postAddPhone(profile: ProfileModel, phone) {
      return this.http.post(
          this.api.getHostVersion() + '/organisation/' + this.storageService.getCurrentOrganisation() + '/profile/' + profile.id + '/recognition-phone',
          phone,
          {headers: this.api.getHeaders()});
 }
}
