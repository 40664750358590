import { Injectable } from '@angular/core';
import {Network} from '@capacitor/network';
import {AlertController} from '@ionic/angular';
import {TranslationService} from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  private alert;

  translations = {
    alertTitle: 'Alert',
    alertSubHeader: 'Connection failure',
    alertMessage: 'It seems you are not connected to internet. Come closer to the surface or use the Bepark Wifi if available.'
  };

  constructor(
      private alertController: AlertController,
      private translationService: TranslationService
  ) {
    this.translationService.loadTranslation('alert', this.translations);
  }

  async presentConnectionAlert() {
    this.alert = await this.alertController.create({
      header: this.translations.alertTitle,
      subHeader: this.translations.alertSubHeader,
      message: this.translations.alertMessage,
      buttons: ['OK']
    });
    await this.alert.present();
  }

  public startListeningOnConnection() {

    Network.addListener('networkStatusChange', networkStatus => {
      const { connectionType} = networkStatus;

      //'wifi' | 'cellular' | 'none' | 'unknown'
      const networkType = connectionType;

      if(networkType !== 'none'){
        // We just got a connection but we need to wait briefly
        // before we determine the connection type. Might need to wait.
        // prior to doing any api requests as well.
        setTimeout(() => {
          if (this.alert) {
            this.alert.dismiss();
          }
        }, 3000);
      } else {
        this.presentConnectionAlert();
      }
    });
  }
}
