import { Injectable } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {HelpdeskPage} from '../modals/helpdesk/helpdesk.page';

@Injectable({
  providedIn: 'root'
})
export class HelpdeskService {

  constructor(
      private modalController: ModalController
  ) { }

  async presentHelpdeskModal() {
    const modal = await this.modalController.create({
        component: HelpdeskPage
    });
    return await modal.present();
  }
}
