import { Component } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {TranslationService} from '../../services/translation.service';
import {AccessModel} from '../../../models/access/access.model';
import {GateSpecificationModel} from '../../../models/access/gateSpecificationModel';

@Component({
  selector: 'app-open-gate-selector',
  templateUrl: './open-gate-selector.page.html',
  styleUrls: ['./open-gate-selector.page.scss'],
})
export class OpenGateSelectorPage {

  translations = {
    title: ''
  };

  access: AccessModel;
  way: string;
  mappedAccessPoints: Array<GateSpecificationModel[]> = [];

  constructor(
    public modalController: ModalController,
    protected translationService: TranslationService,
    public navParams: NavParams
  ) {
        this.access = navParams.get('access');
        this.way = navParams.get('way');
        this.mappedAccessPoints = navParams.get('mappedAccessPoints');
  }

    ionViewDidEnter(): void {
        this.translationService.loadTranslation('open_gate_selector', this.translations);
    }

  public dismissModal() {
    this.modalController.dismiss({
        accessList: null
    });
  }

  public selectThisGate(accessList) {
      this.modalController.dismiss({
          accessList: accessList
      });
  }

}
