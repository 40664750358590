import { Injectable } from '@angular/core';
import {StorageService} from './storage.service';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  version = 'not loaded yet';

  constructor(
      private storage: StorageService,
      private http: HttpClient
  ) {
    this.loadVersion();
  }

  public getHost() {
    return environment.apiHost;
  }

  public getHostVersion() {
    return this.getHost() + '/' + environment.apiVersion;
  }

  public getHeaders() {
    return {
      Authorization: 'Bearer ' + this.storage.getBearer(),
      Accept: 'application/json',
      'X-localization': this.storage.getLanguage(),
      'X-version': this.version
    };
  }

  public doClientCall(callbackCall) {

    const clientHeaders = {
      Authorization: null,
      Accept: 'application/json',
      'X-localization': this.storage.getLanguage(),
      'X-version': this.version
    };

    const oauthData = {
      grant_type: 'client_credentials',
      client_id: environment.apiAppClientId,
      client_secret: environment.apiAppClientSecret
    };

    return this.http.post(this.getHost() + '/oauth/token', oauthData).subscribe((response: BesaasOauthResponseResponse) => {
      clientHeaders.Authorization = 'Bearer ' + response.access_token;
      callbackCall(clientHeaders);
    });
  }

  public loadVersion() {
      return this.version = '5.4.1'; // <== Need to change when updating
  }
}

export interface BesaasOauthResponseResponse extends Response {
  readonly headers: Headers;
  readonly ok: boolean;
  readonly redirected: boolean;
  readonly status: number;
  readonly statusText: string;
  readonly trailer: Promise<Headers>;
  readonly type: ResponseType;
  readonly url: string;
  readonly access_token: string;
  clone(): Response;
}

export interface BesaasResponse extends Response {
  readonly headers: Headers;
  readonly ok: boolean;
  readonly redirected: boolean;
  readonly status: number;
  readonly statusText: string;
  readonly trailer: Promise<Headers>;
  readonly type: ResponseType;
  readonly url: string;
  readonly data: object;
  clone(): Response;
}
