import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'app', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'reset-password', loadChildren: () => import('./modals/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule) },
  { path: 'add-phone', loadChildren: () => import('./modals/add-phone/add-phone.module').then(m => m.AddPhoneModule) },
  { path: 'open-gate', loadChildren: () => import('./openGate/openGate.module').then(m => m.OpenGatePageModule) },
  { path: 'create-time-spot-slot', loadChildren: () => import('./modals/create-time-spot-slot/create-time-spot-slot.module').then(m => m.CreateTimeSpotSlotPageModule) },
  { path: 'helpdesk', loadChildren: () => import('./modals/helpdesk/helpdesk.module').then(m => m.HelpdeskPageModule) },
  { path: 'open-gate-selector', loadChildren: () => import('./modals/open-gate-selector/open-gate-selector.module').then(m => m.OpenGateSelectorPageModule) },
  { path: 'new-booking', loadChildren: () => import('./modals/new-booking/new-booking.module').then(m => m.NewBookingPageModule) },
  { path: 'new-booking-availability', loadChildren: () => import('./modals/new-booking-availability/new-booking-availability.module').then(m => m.NewBookingAvailabilityPageModule) },
  {
    path: 'add-vehicle-page',
    loadChildren: () => import('./modals/add-vehicle-page/add-vehicle-page.module').then( m => m.AddVehiclePagePageModule)
  },
  { path: '**', redirectTo: 'login' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
