import {Deserializable} from '../deserializable.model';
import {PhoneModel} from './phone.model';
import {VehicleModel} from "./vehicle.model";

export class ProfileModel implements Deserializable {
    email: string;
    firstname: string;
    id: string;
    language: string;
    lastname: string;
    phones: Array<PhoneModel> = [];
    vehicles: Array<VehicleModel> = [];

    constructor() {}

    deserialize(input: any) {
        this.phones = [];

        for (let i = 0, len = input.phones.length; i < len; i++) {
            const phone = new PhoneModel();
            phone.deserialize(input.phones[i]);
            this.phones.push(phone);
        }

        delete input.phones;

        this.vehicles = [];

        for (let i = 0, len = input.vehicles.length; i < len; i++) {
            const vehicle = new VehicleModel();
            vehicle.deserialize(input.vehicles[i]);
            this.vehicles.push(vehicle);
        }

        delete input.vehicles;

        Object.assign(this, input);
        return this;
    }
}
