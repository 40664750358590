import {Deserializable} from '../deserializable.model';

export class VehicleModel implements Deserializable {

    vehicle_id: number;
    plate: string = '';

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
