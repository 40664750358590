import {Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { Device } from '@capacitor/device';

const getLocaleCode = async () => await Device.getLanguageTag();

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
      private translate: TranslateService,
      private storage: StorageService,
  ) {
    this.detectLanguage();
  }

  public detectLanguage() {
    getLocaleCode().then( (res) => {
        const locale = this.getValidLocal(res.value);
        this.changeLanguage(this.getShortLangName(locale));
        this.setLocalId(locale);
      }, (error) => {
          const locale = this.getValidLocal(navigator.language);
          this.changeLanguage(this.getShortLangName(locale));
          this.setLocalId(locale);
      });
    }

    public getValidLocal(locale) {
        const splitted = this.getShortLangName(locale);

        if (! ['en', 'fr', 'nl', 'es'].includes(splitted)) {
            return 'en-GB';
        } else {
            return locale;
        }
    }

    public getShortLangName(locale) {
        const splitted = locale.split('-');

        if (splitted.length === 0) {
            return 'en';
        } else {
            return splitted[0];
        }
    }

  public changeLanguage(newLanguage) {
    this.storage.setLanguage(newLanguage);
  }

  private getTranslationObserver(page) {
    this.translate.use(this.storage.getLanguage() + '/' + page);
    return this.translate.get(page);
  }

  public loadTranslation(page, translations) {
    const currentScope = this;
    Object.keys(translations).forEach((key) => {
      currentScope.getTranslationObserver(page).subscribe((res: string) => {
        translations[key] = res[key];
      });
    });

    return translations;
  }

  public setLocalId(lang) {
    this.storage.setLocalId(lang);
  }

  public getLocalId() {
    if(this.storage.getLocalId() === null || this.storage.getLocalId() === undefined || this.storage.getLocalId().length === 0)
    {
        this.setLocalId('en-US');
        this.detectLanguage();
    }

    return this.storage.getLocalId();
  }
}
