import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../services/storage.service';
import { ApiService } from '../../services/api.service';
import { AlertController } from '@ionic/angular';
import { TranslationService } from '../../services/translation.service';
import { Barcode, BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  public scanSubscriber;
  barcodes: Barcode[] = [];
  code: string;
  isQrEnabled: boolean = false;

  translations = {
    permission_missing_title: '',
    permission_missing_subtitle: '',
    permission_missing_txt: '',
    error: '',
    error_message: ''
  };

  constructor(
      private api: ApiService,
      private http: HttpClient,
      private storage: StorageService,
      private alertController: AlertController,
      private translationService: TranslationService
  ) {
      this.translationService.loadTranslation('login_service', this.translations);
  }

  public postLogin(loginForm, callbackSuccess, callbackError) {

    const oauthData = {
        username: loginForm.email,
        password: loginForm.password,
        grant_type: 'password',
        client_id: environment.apiClientId,
        client_secret: environment.apiClientSecret
    };

    return this.callOauthToken(oauthData, callbackSuccess, callbackError);
  }

  public logout() {
      this.storage.cleanOAuth();
  }

  public isLogged() {
      return this.storage.getBearer() !== null && this.storage.getBearer().length > 0;
  }

  private callOauthToken(oauthData, callbackSuccess?, callbackError?) {
      return this.http.post(this.api.getHost() + '/oauth/token', oauthData).subscribe((response) => {
          this.storage.storeOAuthResponse(response);
          if (callbackSuccess !== null) {
              callbackSuccess(response);
          }
      }, (response) => {
          if (callbackError !== null) {
              callbackError(response);
          }
      });
  }

  public async loginWithQrCode(callbackSuccess, callbackError) {

     await BarcodeScanner.requestPermissions();

     // Check if the Google ML Kit barcode scanner is available
     await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable().then(async (data) => {
       if (data.available) {
         // Start the barcode scanner
         await this.startScanner().then(async (barcodes) => {
           this.code = barcodes[0].rawValue;
           return this.loginWithCode(this.code, callbackSuccess, callbackError);
         });
       } else {
         // Install the Google ML Kit barcode scanner
         await BarcodeScanner.installGoogleBarcodeScannerModule().then(async () => {
           await this.startScanner().then(async (barcodes) => {
             this.code = barcodes[0].rawValue;
             return this.loginWithCode(this.code, callbackSuccess, callbackError);
           });
         });
       }
     }).catch(() => {
       BarcodeScanner.checkPermissions().then((status)=> {
        if (status.camera === 'granted') {
          // The camera is visible behind the WebView, so that you can customize the UI in the WebView.
          // However, this means that you have to hide all elements that should not be visible.
          // You can find an example in our demo repository.
          // In this case we set a class `barcode-scanner-active`, which then contains certain CSS rules for our app.
          // document.querySelector('app')?.classList.add('barcode-scanner-active');
          (window.document.querySelector('ion-app') as HTMLElement).classList.add('cameraView');
          // Add the `barcodeScanned` listener
          const listener = BarcodeScanner.addListener(
            'barcodeScanned',
            async result => {
              this.closeQrLogin();
              return this.loginWithCode(result.barcode.rawValue, callbackSuccess, callbackError);
            },
          );
          this.isQrEnabled = true;
          // Start the barcode scanner
          BarcodeScanner.startScan();

        } else if (status.camera === 'denied') {
          // camera permission was permanently denied, go to settings
          this.presentAlert(
            this.translations.permission_missing_title,
            this.translations.permission_missing_subtitle,
            this.translations.permission_missing_txt
          ).then(() => {
            BarcodeScanner.openSettings();
          });
        } else {
          // permission was denied, but not permanently. You can ask for permission again at a later time.
          this.presentAlert(
            this.translations.permission_missing_title,
            this.translations.permission_missing_subtitle,
            this.translations.permission_missing_txt
          ).then(() => {
            BarcodeScanner.openSettings();
          });
        }
      })
     });
   }

  async presentAlert(header, subheader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async startScanner() {
    const { barcodes } = await BarcodeScanner.scan({
      formats: [BarcodeFormat.QrCode, BarcodeFormat.Ean13]
    });
    return barcodes;
  }

  public loginWithCode(code: string, callbackSuccess, callbackError) {

      const codeData = code.split('|');

      const oauthData = {
          remember_token: codeData[1],
          user_id: codeData[0],
          grant_type: 'remember_token',
          client_id: environment.apiClientId,
          client_secret: environment.apiClientSecret
      };

      return this.callOauthToken(oauthData, callbackSuccess, callbackError);
  }

  public closeQrLogin() {
      // Make all elements in the WebView visible again
      (window.document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');

      // Remove all listeners
      BarcodeScanner.removeAllListeners();

      // Stop the barcode scanner
      BarcodeScanner.stopScan();

      this.isQrEnabled = false;
  }
}
