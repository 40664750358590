import { Injectable } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '../../services/storage.service';

@Injectable({
  providedIn: 'root'
})

export class AccessesService {

  constructor(
      private api: ApiService,
      private http: HttpClient,
      private storageService: StorageService
  ) { }

  public getAccesses(strategiesToLoad = []) {
    const organisation = this.storageService.getCurrentOrganisation();
    const strategies = strategiesToLoad.join(',');

    return this.http.get(this.api.getHost() + '/mobile/access-control/organizations/' + organisation + '/accesses?strategies=' + strategies, {headers: this.api.getHeaders()});
  }
}
