import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../services/api.service';

@Injectable({
  providedIn: 'root'
})

export class ResetPasswordService {

  constructor(
      private api: ApiService,
      private http: HttpClient
  ) { }

 public postResetPassword(email, callBackSuccess, callBackError) {
    return this.api.doClientCall( (clientHeader) => {
        this.http.post(
            this.api.getHostVersion() + '/reset-password',
            {
                email: email
            },
            {
                headers: clientHeader
            }
        ).subscribe((response) => {
            callBackSuccess(response);
            },
            (error) => {
            callBackError(error);
            }
        );
    });
 }
}
