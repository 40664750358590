// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item, ion-list, ion-title, ion-toolbar, ion-header, ion-label {
  --ion-text-color: #000;
  --ion-background-color:transparent;
}

ion-list-header {
  --ion-text-color: #000;
  --ion-color-step-850:white;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/open-gate-selector/open-gate-selector.page.scss"],"names":[],"mappings":"AACA;EACC,sBAAA;EACA,kCAAA;AAAD;;AAEA;EACC,sBAAA;EACA,0BAAA;AACD","sourcesContent":["\nion-item, ion-list, ion-title, ion-toolbar, ion-header, ion-label{\n\t--ion-text-color: #000;\n\t--ion-background-color:transparent;\n}\nion-list-header{\n\t--ion-text-color: #000;\n\t--ion-color-step-850:white;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
