import { Injectable } from '@angular/core';
import { ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

    constructor(
        private toastController: ToastController
    ) { }

    async presentToast(textMessage = '', duration = 1500) {
        const toast = await this.toastController.create({
            message: textMessage,
            duration: duration,
            color: 'dark'
        });
        toast.present();
    }
}
