import {Component} from '@angular/core';
import {AlertController, ModalController, NavController, NavParams} from '@ionic/angular';
import {TranslationService} from '../../services/translation.service';
import {ToastService} from '../../services/toast.service';
import {BarcodeFormat} from '@zxing/library';
import {ApiService, BesaasResponse} from '../../services/api.service';
import {route} from '../../../environments/route';
import {LoginService} from '../../login/services/login.service';
import {OrganisationService} from '../../tabs/services/organisation.service';
import {HelpdeskService} from '../../services/helpdesk.service';
import {ErrorManagerServiceService} from '../../services/errorManagerService.service';

@Component({
  selector: 'app-qr-code-scanner',
  templateUrl: './qr-code-scanner.page.html',
})
export class QrCodeScannerPage  {

  showLoader = false;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.QR_CODE,
  ];

  constructor(
      public alertController: AlertController,
      public toastService: ToastService,
      public navController: NavController,
      public loginService: LoginService,
      public modalController: ModalController,
      private organisationService: OrganisationService,
      private apiService: ApiService,
      private translationService: TranslationService,
      private helpdeskService: HelpdeskService,
      private errorManagerServiceService: ErrorManagerServiceService,
      public navParams: NavParams
  ) {}

  public handleQrSuccess(barcode: string) {
    this.showLoader = true;
    this.modalController.dismiss();
    this.loginService.loginWithCode(barcode, (response) => {
      this.organisationService.getOrganisations().subscribe((organisationResponse: BesaasResponse) => {
        this.organisationService.switchOrganisation(organisationResponse.data[0].id);
        this.toastService.presentToast(this.translations.login_success);
        this.showLoader = false;
        this.navController.navigateForward(route.access);
      }, (organisationResponse) => {
        this.showLoader = false;
        this.presentAlert(this.translations.login_failed);
        this.errorManagerServiceService.handle(organisationResponse, false);
      });
    }, (response) => {
      this.modalController.dismiss();
      this.presentAlert(this.translations.login_failed);
      this.showLoader = false;
    });
  }

  translations = {
    login_with_qr: '',
    login_success: '',
    login_failed: '',
  };

  async presentAlert(message) {
    const alert = await this.alertController.create({
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  ionViewDidEnter(): void {
    this.translationService.loadTranslation('login', this.translations);
  }

  public dismissModal() {
    this.modalController.dismiss();
  }
}
