import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {AlertController, NavController} from '@ionic/angular';
import {route} from '../../environments/route';
import {StorageService} from './storage.service';
import {TranslationService} from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorManagerServiceService {

  protected translations = {
      general_error_title: '',
      general_error_subtitle: '',
      error_profile_no_access: '',
      outDatedAlertTitle: '',
      outDatedAlertSubHeader: '',
      outDatedAlertMessage: '',
      gateWayErrorTitle: '',
      gateWayAlertSubtitle: '',
      gateWayAlertMessage: '',
      api_error_door_failure: '',
      api_error_no_access: '',
      api_error_gate_unknown: '',
      bad_configuration_timetable: '',
      no_spot_left: '',
      invalid_timespan: '',
      no_time_available: '',
      time_spot_slot_exist_in_timeframe: '',
      no_credit: '',
      not_enough_credit: '',
      no_wallet: '',
      time_spot_slot_outside_day_limit: '',
      check_position_error_too_far: '',
      check_position_gps_coordinate_invalid: '',
      validationErrorTitle: '',
      end_date_time_required_must_refresh: ''
  };

  constructor(
    private apiService: ApiService,
    private alertController: AlertController,
    private storage: StorageService,
    private navController: NavController,
    private translationService: TranslationService
  ) {
    translationService.loadTranslation('error', this.translations);
  }

  public handle(error, displayInAlert: boolean = true, alertHandler = null) {

    switch (error.status) {
        case 301:
            this.manage301Error(error);
            break;
        case 400:
            const manager400 = this.manage400Error(error, displayInAlert, alertHandler);

            if (displayInAlert) {
                return manager400;
            }

            break;
        case 401:
            this.manage401Error(error);
            break;
        case 422:
            this.manage422Error(error);
            break;
        case 504:
            this.manage504Error(error);
            break;
        default:
            this.manageUnknownError(error);
    }
  }

  protected manage400Error(error, displayInAlert, alertHandler) {
    let message: string;
    let errorMessage;

    if(error.error.version === 1)
    {
        const errorData = error.error.data;

        // In next version of the api, error_code will be removed and replaced by message key.
        // This piece of code allow the app to work with both version.
        if (errorData.message !== undefined) {
            errorMessage = errorData.message;
        } else if (errorData.error_code !== undefined) {
            errorMessage = errorData.error_code;
        }
    }
    else if(error.error.version === 2)
    {
        errorMessage = error.error.error_description;
    }
    else
    {
        errorMessage = JSON.stringify(error.error);
    }

    switch (errorMessage) {
        case 'PROFILE_NOT_ALLOWED':
            message = this.translations.error_profile_no_access;
            break;
        case 'ERROR_REACHING_DOOR':
            message = this.translations.api_error_door_failure;
            break;
        case 'ERROR_OUT_OF_ACCESS_HOUR':
            message = this.translations.api_error_no_access;
            break;
        case 'ERROR_UNKNOWN_GATE_KIND':
            message = this.translations.api_error_gate_unknown;
            break;
        case 'BAD_CONFIGURATION_TIMETABLE':
            message = this.translations.bad_configuration_timetable;
            break;
        case 'no_spot_left':
            message = this.translations.no_spot_left;
            break;
        case 'invalid_timespan':
            message = this.translations.invalid_timespan;
            break;
        case 'no_time_available':
            message = this.translations.no_time_available;
            break;
        case 'no_credit':
            message = this.translations.no_credit;
            break;
        case 'not_enough_credit':
            message = this.translations.not_enough_credit;
            break;
        case 'no_wallet':
            message = this.translations.no_wallet;
            break;
        case 'time_spot_slot_exist_in_timeframe':
            message = this.translations.time_spot_slot_exist_in_timeframe;
            break;
        case 'time_spot_slot_outside_day_limit':
            message =  this.translations.time_spot_slot_outside_day_limit;
            break;
        case 'gps_coordinate_invalid':
            message = this.translations.check_position_gps_coordinate_invalid;
            break;
        case 'too_far_from_access_point':
            message = this.translations.check_position_error_too_far;
            break;
        case 'The end date time is required to open an On the fly booking access the first time':
            message = this.translations.end_date_time_required_must_refresh;
            break;
      default:
          message = error.error.data.error_code;
    }

    if (displayInAlert) {
      this.presentAlert(message, alertHandler);
    } else {
      return message;
    }
  }

  protected manage401Error(error) {
      this.storage.cleanOAuth();
      this.navController.navigateForward(route.login);
  }

  protected manage301Error(error) {
      if (error.error.data.error_code === 'OUTDATED_APP') {
          this.presentAlert(this.translations.outDatedAlertTitle, this.translations.outDatedAlertSubHeader, this.translations.outDatedAlertMessage).then(() => {
              this.storage.cleanOAuth();
              this.navController.navigateForward(route.login);
          });
      }
  }

  protected manage422Error(error) {
        this.presentAlert( error.error.errors.plate[0], '', this.translations.validationErrorTitle);
  }

  protected manage504Error(error) {
      this.presentAlert(this.translations.gateWayErrorTitle, this.translations.gateWayAlertSubtitle, this.translations.gateWayAlertMessage);
  }

  protected manageUnknownError(error) {
      // alert('An uncatched call to the api has failed with message : ' + error.message);
  }

  async presentAlert(message, handler = null, header: string = null, subheader: string = null) {

    if (handler === null) {
        handler = () => {};
    }

    if (header === null) {
        header = this.translations.general_error_title;
    }

    if (subheader === null) {
        subheader = this.translations.general_error_subtitle;
    }

    const alert = await this.alertController.create({
        header: header,
        subHeader: subheader,
        message: message,
        buttons: [
            {
                text: 'OK',
                handler: () => {
                    handler();
                }
            }
        ]
    });

    await alert.present();
  }
}
