import {Deserializable} from '../deserializable.model';

export class TimespotslotavailabilityModel implements Deserializable {

    from: Date;
    to: Date;
    formatted: string;

    constructor() {}

    deserialize(input: any) {

        input.from = new Date(input.from);
        input.to = new Date(input.to);

        Object.assign(this, input);

        return this;
    }
}
