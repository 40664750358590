// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item, ion-list, ion-title, ion-toolbar, ion-header, ion-label, ion-title, ion-icon {
  --ion-text-color:white;
  color: white;
  --ion-background-color:transparent;
}

ion-list-header {
  --ion-text-color:white;
  --ion-color-step-850:white;
}

ion-card {
  --ion-text-color:white;
  --ion-color-step-850:white;
}

ion-item {
  --ion-background-color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/modals/helpdesk/helpdesk.page.scss"],"names":[],"mappings":"AACA;EACC,sBAAA;EACC,YAAA;EACD,kCAAA;AAAD;;AAEA;EACC,sBAAA;EACA,0BAAA;AACD;;AAEA;EACC,sBAAA;EACA,0BAAA;AACD;;AAEA;EACC,gDAAA;AACD","sourcesContent":["\nion-item, ion-list, ion-title, ion-toolbar, ion-header, ion-label, ion-title, ion-icon{\n\t--ion-text-color:white;\n  color: white;\n\t--ion-background-color:transparent;\n}\nion-list-header{\n\t--ion-text-color:white;\n\t--ion-color-step-850:white;\n}\n\nion-card{\n\t--ion-text-color:white;\n\t--ion-color-step-850:white;\n}\n\nion-item {\n\t--ion-background-color: var(--ion-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
