import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslationService } from '../../services/translation.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.page.html',
  styleUrls: ['./helpdesk.page.scss'],
})
export class HelpdeskPage {

    translations = {
        title: '',
        emergencies: '',
        questions_and_issues: '',
        helpdesk_link: '',
        helpdesk_link_text: '',
        troubleshoot_connection_link: '',
        troubleshoot_opening_link: '',
        troubleshoot_connection_text: '',
        troubleshoot_opening_text: ''
    };

    showLoader = false;

    constructor(
        private translationService: TranslationService,
        private apiService: ApiService,
        private modalController: ModalController,
    ) { }

    ionViewDidEnter(): void {
        this.translationService.loadTranslation('helpdesk', this.translations);
    }

    public dismissModal() {
        this.modalController.dismiss();
    }

    public getAppVersion() {
        return this.apiService.version;
    }
}
