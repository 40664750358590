import {Component, Input} from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import {AddPhoneService} from './services/addPhone.service';
import {TranslationService} from '../../services/translation.service';
import {ProfileService} from '../../tabs/services/profile.service';
import {ApiService, BesaasResponse} from '../../services/api.service';
import {ProfileModel} from '../../../models/profile/profile.model';
import {OrganisationService} from '../../tabs/services/organisation.service';
import {route} from '../../../environments/route';
import {LoginService} from '../../login/services/login.service';
import {ErrorManagerServiceService} from '../../services/errorManagerService.service';
import {ToastService} from '../../services/toast.service';
import {Events} from '../../services/Events';

@Component({
  selector: 'app-add-phone',
  templateUrl: './add-phone.page.html',
  styleUrls: ['./add-phone.page.scss'],
})
export class AddPhonePage  {

  showLoader = false;
  profile = new ProfileModel();
  prefixes;
  phone = {
    prefix: '',
    number: ''
  };

  translations = {
    title: '',
    submit_btn: '',
    number: '',
    prefix: '',
    phone_add_successfull: '',
    form_invalid: ''
  };

  constructor(
      private navController: NavController,
      private addPhoneService: AddPhoneService,
      private toastService: ToastService,
      private modalController: ModalController,
      private translationService: TranslationService,
      private profileService: ProfileService,
      public navParams: NavParams,
      private apiService: ApiService,
      private organisationService: OrganisationService,
      private events: Events,
      private loginService: LoginService,
      private errorManagerServiceService: ErrorManagerServiceService
  ) {
    {
      this.prefixes = profileService.getPrefixes();
    }
  }

  ionViewDidEnter(): void {

    if (!this.loginService.isLogged()) {
      this.navController.navigateRoot(route.login);
    }

    this.translationService.loadTranslation('addphone', this.translations);
  }

  public loadProfile() {
    this.showLoader = true;
    this.organisationService.getCurrentProfile().subscribe((response: BesaasResponse) => {
      this.profile = new ProfileModel();
      this.profile.deserialize(response.data);
      this.showLoader = false;
    }, (response) => {
      this.showLoader = false;
      this.errorManagerServiceService.handle(response);
    });
  }

  public processForm() {
     this.showLoader = true;
     this.loadProfile();
     if (this.isFormValid()) {
       this.addPhoneService.postAddPhone(this.profile, this.phone).subscribe((response: BesaasResponse) => {
         this.showLoader = false;
         this.modalController.dismiss();
         this.toastService.presentToast(this.translations.phone_add_successfull);
         this.events.publish('reload_profile');
       }, (response) => {
         this.showLoader = false;
         this.errorManagerServiceService.handle(response);
       });
       this.showLoader = false;
     } else {
       this.toastService.presentToast(this.translations.form_invalid);
     }
  }

  public dismissModal() {
    this.modalController.dismiss();
  }
  private isFormValid() {

    if (this.phone.number.length < 1 || this.phone.prefix.length === 0) {
      return false;
    }

    return true;
  }
}
