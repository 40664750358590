import { Component } from '@angular/core';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {TranslationService} from '../../services/translation.service';

@Component({
  selector: 'app-create-time-spot-slot',
  templateUrl: './create-time-spot-slot.page.html',
  styleUrls: ['./create-time-spot-slot.page.scss'],
})
export class CreateTimeSpotSlotPage {

  availableFrom;
  availableTo;
  showLoader = false;
  dropdown: any[] = [];

  translations = {
    title: '',
    to: '',
    submit_btn: '',
    ok: '',
    cancel: '',
    help_text: '',
    form_error: '',
    intro_text: ''
  };

  timeSpotSlotForm = {
    from: null,
    to: null
  };

  constructor(
    private modalController: ModalController,
    public navParams: NavParams,
    private translationService: TranslationService,
    private alertController: AlertController
  ) {
    this.availableFrom = navParams.get('availableFrom');
    this.availableTo = navParams.get('availableTo');
  }

    async presentAlert(message) {
        const alert = await this.alertController.create({
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }

  ionViewDidEnter(): void {
    this.translationService.loadTranslation('createtimespotslot', this.translations);
    this.generateDropdown();
  }

  public generateDropdown() {

    const availableFromTime = new Date(this.availableFrom).valueOf();
    const availableToTime = new Date(this.availableTo).valueOf();

    const incrementalStep = 30 * 60000; // =30 minutes
    const maxTimeInMs = 48 * 60 * 60000;
    let extraMinutes = incrementalStep;

    if ((availableFromTime + extraMinutes) > availableToTime) {
        /*alert('Issue with OTFB access. The available from time is greater than maximum time. ' +
            'From: ' + availableFromTime + ' - Extraminutes addess : ' + extraMinutes + ' - To time : ' + availableToTime);*/
    }

    while ( (availableFromTime + extraMinutes) <= availableToTime && extraMinutes < maxTimeInMs) {

        this.dropdown.push(
            {to: this.addMinutes(new Date(this.availableFrom), extraMinutes), value: this.getHumanReadableTime(extraMinutes)}
        );

        extraMinutes += incrementalStep;
    }
  }

  protected addMinutes(dateTime: Date, minutes: number) {
    return new Date(dateTime.valueOf() + minutes);
  }

  protected getHumanReadableTime(timeInMs: number) {

    const date = new Date(timeInMs);
    const day = date.getUTCDate() > 1 ? (date.getUTCDate() - 1) + ' day ' : '';
    const hours = date.getUTCHours() > 0 ? date.getUTCHours() + 'H' : '';
    const timeInReal = new Date((timeInMs + Date.now().valueOf()));
    let minutes = '';

    if (date.getUTCMinutes() > 0 && date.getUTCHours() === 0) {
        minutes = date.getUTCMinutes() + ' minutes';
    } else if (date.getUTCMinutes() > 0) {
        minutes = date.getUTCMinutes().toString() + ' (' + timeInReal.getHours() + ':' + timeInReal.getMinutes() + ')';
    } else {
        minutes = ' (' + timeInReal.getHours() + ':' + timeInReal.getMinutes() + ')';
    }

    return '+' + day + hours + minutes;
  }

  public processForm() {

     if (this.timeSpotSlotForm.to === null) {
        this.presentAlert(this.translations.form_error);
     } else {
         this.modalController.dismiss({
             from: this.timeSpotSlotForm.from,
             to: this.timeSpotSlotForm.to
         });
     }
  }

  public dismissModal() {
    this.modalController.dismiss();
  }
}
