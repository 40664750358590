import {Deserializable} from '../deserializable.model';

export class PhoneModel implements Deserializable {

    id: number;
    prefix: number;
    number: string;
    msisdn: string;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
