import {Component, Input} from '@angular/core';
import {AlertController, ModalController, NavController, NavParams} from '@ionic/angular';
import {ResetPasswordService} from './services/resetPassword.service';
import {TranslationService} from '../../services/translation.service';
import {ToastService} from '../../services/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage  {

  showLoader = false;
  email = null;

  translations = {
    title: 'Reset password',
    label_email: 'Email',
    submit_btn: 'Reset',
    error_email_required: 'Email is required and need to be valid',
    success: 'Done !',
    error: 'An error occured, please try again later',
    invalid_email: ''
  };

  resetForm = {
    email: null
  };

  constructor(
      private navController: NavController,
      private resetPasswordService: ResetPasswordService,
      private toastService: ToastService,
      public alertController: AlertController,
      private modalController: ModalController,
      private translationService: TranslationService,
      public navParams: NavParams
  ) {
    this.resetForm.email = navParams.get('email');
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  ionViewDidEnter(): void {
    this.translationService.loadTranslation('resetpassword', this.translations);
  }

  public processForm() {
    this.showLoader = true;

    if (this.isFormValid()) {
      this.presentAlert(this.translations.error_email_required);
      this.showLoader = false;
    } else {
      this.resetPasswordService.postResetPassword(this.resetForm.email, (response) => {
        if (response.data.status === true) {
          this.showSuccess(this.translations.success);
        } else {
          this.showError(this.translations.invalid_email);
        }
      }, (error) => {
        this.showError(this.translations.error);
      });
    }
  }

  private showSuccess(msg) {
    this.showLoader = false;
    this.toastService.presentToast(msg);
    this.dismissModal();
  }

  private showError(msg) {
    this.showLoader = false;
    this.presentAlert(msg);
  }

  public dismissModal() {
    this.modalController.dismiss();
  }

  private isFormValid() {
    return (this.resetForm.email === null || this.resetForm.email.length <= 5);
  }
}
