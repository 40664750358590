import {NgModule, ErrorHandler, Injectable, Injector, LOCALE_ID} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LoginService} from './login/services/login.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {StorageService} from './services/storage.service';
import {ApiService} from './services/api.service';
import {OrganisationService} from './tabs/services/organisation.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslationService} from './services/translation.service';
import {ResetPasswordPageModule} from './modals/reset-password/reset-password.module';
import {QrCodeScannerPageModule} from './modals/qr-code-scanner/qr-code-scanner.module';
import { AddPhoneModule } from './modals/add-phone/add-phone.module';
import {AccessesService} from './tabs/services/accesses.service';
import {GeolocationService} from './services/geolocation.service';
import {HelpdeskService} from './services/helpdesk.service';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {NetworkService} from './services/network.service';
import {DatePipe} from '@angular/common';
import {CreateTimeSpotSlotPageModule} from './modals/create-time-spot-slot/create-time-spot-slot.module';
import {ErrorManagerServiceService} from './services/errorManagerService.service';
import {HelpdeskPageModule} from './modals/helpdesk/helpdesk.module';
import {LoaderService} from './services/loader.service';
import {OpenGateSelectorPageModule} from './modals/open-gate-selector/open-gate-selector.module';
import {NewBookingPageModule} from './modals/new-booking/new-booking.module';
import {NewBookingAvailabilityPageModule} from './modals/new-booking-availability/new-booking-availability.module';
import {ToastService} from './services/toast.service';
import {DateService} from './services/date.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import {AuthenticationInterceptor} from "./services/authenticationInterceptor";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot(),
        AppRoutingModule,
        ResetPasswordPageModule,
        QrCodeScannerPageModule,
        CreateTimeSpotSlotPageModule,
        HelpdeskPageModule,
        AddPhoneModule,
        OpenGateSelectorPageModule,
        NewBookingPageModule,
        NewBookingAvailabilityPageModule,
        //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: LOCALE_ID,
            deps: [TranslationService],
            useFactory: (translationService: TranslationService) => translationService.getLocalId()
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        StorageService,
        ApiService,
        LoginService,
        OrganisationService,
        TranslationService,
        AccessesService,
        GeolocationService,
        HelpdeskService,
        NetworkService,
        DatePipe,
        ErrorManagerServiceService,
        LoaderService,
        ToastService,
        DateService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
