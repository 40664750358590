import { Injectable } from '@angular/core';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {
    // https://angular.io/api/common/DatePipe
    constructor(
        private datePipe: DatePipe
    ) { }

    public formatToTimeString(dateTime) {
        return this.format(this.toDateObject(dateTime), 'HH:mm');
    }

    public formatToDate(dateTime) {
        return this.format(this.toDateObject(dateTime), 'dd/MM/yyyy');
    }

    public formatToIso8601(dateTime, seconds = '-1') {
        let secondsToFormat = 'ss';

        if (parseInt(seconds) >= 0) {
            secondsToFormat = seconds;
        }

        return this.format(this.toDateObject(dateTime), 'yyyy-MM-ddTHH:mm:' + secondsToFormat + 'ZZZZZ');
    }

    public format(dateTime, format: string) {
        return this.datePipe.transform(this.toDateObject(dateTime), format);
    }

    public transform(dateTime, format: string) {
        try {
            return this.format(dateTime, format);
        } catch (error) {
         const dateTimeObject = this.toDateObject(dateTime);
         return (dateTimeObject.getDay() < 10 ? '0' : '') + dateTimeObject.getDay() + '' +
             '/' + (dateTimeObject.getMonth() < 10 ? '0' : '') + dateTimeObject.getMonth() + '' +
             ' ' + (dateTimeObject.getHours() < 10 ? '0' : '') + dateTimeObject.getHours() + '' +
             ':' + (dateTimeObject.getMinutes() < 10 ? '0' : '') + dateTimeObject.getMinutes();
        }
    }

    public getUnixTime(dateTime) {
        return this.toDateObject(dateTime).valueOf();
    }

    public toDateObject(dateTime) {
        if (dateTime instanceof Date) {
            return dateTime;
        } else {
            return new Date(dateTime);
        }
    }

    public isToday(dateTime) {
        return this.format(dateTime, 'yyyy-MM-dd') === this.format(new Date(), 'yyyy-MM-dd');
    }
}
