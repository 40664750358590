import { Injectable } from '@angular/core';
import {delay} from 'q';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

    public loader = null;
    public hasRunningLoading = false;

    constructor(
        private loadingController: LoadingController
    ) { }

    async showGateLoader(textMessage = '', maxDuration: number = 0) {
        this.loader = await this.loadingController.create({
            spinner: 'bubbles',
            duration: maxDuration * 1000,
            message: textMessage,
            translucent: true,
            cssClass: 'custom-class custom-loading'
        });

        await this.loader.present();
    }

    async hideGateLoader(hideAfter = 0) {
        await delay(hideAfter).then(() => {
            this.loader.dismiss();
        });
    }
}
