import {Deserializable} from '../deserializable.model';
import {TimespotslotavailabilityModel} from './timespotslotavailability.model';

export class TimespotslotsavailabilityModel implements Deserializable {

    public availability: Array<TimespotslotavailabilityModel> = [];

    constructor() {}

    deserialize(input: any) {

        this.availability = [];

        for (let i = 0, len = input.length; i < len; i++) {
            const availability = new TimespotslotavailabilityModel();
            availability.deserialize(input[i]);
            this.availability.push(availability);
        }

        return this;
    }
}
