// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-hours {
  margin: 1px 0;
}

.hours-list {
  border-bottom: 1px solid white;
  width: 80vw;
  background: var(--ion-color-secondary);
  border-radius: 5px;
  margin: 0 16px;
}

.real-hour {
  width: 100%;
  margin-left: 10px;
  border-bottom: 3px solid var(--ion-color-secondary);
}

.hour-title {
  width: 45px;
  color: #ffffff;
  font-size: 11px;
  font-weight: 700;
}

.hour {
  display: flex;
}

.quarter {
  border-bottom: 1px solid var(--ion-color-secondary);
  font-size: 10px;
  color: white;
  float: right;
  text-decoration: overline;
  width: 250px;
  height: 14px;
  background: #ffffff;
  border-radius: 3px;
  margin: 0 auto;
}
.quarter span {
  position: absolute;
  left: 15px;
}
.quarter.split {
  margin-top: 2px;
}

.quarter-full {
  background: rgba(255, 255, 255, 0.2117647059);
}

ion-title h1 {
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/new-booking-availability/new-booking-availability.page.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,8BAAA;EACA,WAAA;EACA,sCAAA;EACA,kBAAA;EACA,cAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;EACA,mDAAA;AACF;;AAEA;EACE,WAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,mDAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;AACF;AAAE;EACD,kBAAA;EACA,UAAA;AAED;AAAE;EACD,eAAA;AAED;;AAEA;EACE,6CAAA;AACF;;AAGE;EACE,eAAA;AAAJ","sourcesContent":[".container-hours{\n  margin: 1px 0;\n}\n\n.hours-list{\n  border-bottom: 1px solid white;\n  width: 80vw;\n  background: var(--ion-color-secondary);\n  border-radius: 5px;\n  margin: 0 16px;\n}\n\n.real-hour{\n  width: 100%;\n  margin-left: 10px;\n  border-bottom: 3px solid var(--ion-color-secondary);\n}\n\n.hour-title{\n  width: 45px;\n  color: #ffffff;\n  font-size: 11px;\n  font-weight: 700;\n}\n\n.hour{\n  display: flex;\n}\n\n.quarter{\n  border-bottom: 1px solid var(--ion-color-secondary);\n  font-size: 10px;\n  color: white;\n  float: right;\n  text-decoration: overline;\n  width: 250px;\n  height: 14px;\n  background: #ffffff;\n  border-radius: 3px;\n  margin: 0 auto;\n  span{\n\tposition: absolute;\n\tleft: 15px;\n  }\n  &.split{\n\tmargin-top: 2px;\n  }\n}\n\n.quarter-full{\n  background: #ffffff36;\n}\n\nion-title{\n  h1{\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
