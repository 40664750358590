import { Component } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {TimespotslotavailabilityModel} from '../../../models/calendar/timespotslotavailability.model';
import {TranslationService} from '../../services/translation.service';

@Component({
  selector: 'app-new-booking-availability',
  templateUrl: './new-booking-availability.page.html',
  styleUrls: ['./new-booking-availability.page.scss'],
})
export class NewBookingAvailabilityPage {

  translations = {
      title: ''
  };

  showLoader = false;
  quarterHours = ['00', '15', '30', '45'];
  splitValue = this.quarterHours.length;
  splitedDays = this.splitDay(this.splitValue);

  // Preserve original property order
  originalOrder = (a, b): number => {
    return 0;
  }

  timeslostsAvailability: Array<TimespotslotavailabilityModel> = [];
  pivotDate: Date;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private translationService: TranslationService
  ) {
    this.timeslostsAvailability = navParams.get('timeslostsAvailability');
    this.pivotDate = navParams.get('pivotDate');
  }

  public getHoursWithQuarter() {
    return this.splitedDays;
  }

  public splitDay(splitValue) {
    const times = [];
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < splitValue; j++) {
            let time = i + ':' + this.quarterHours[j];
            if (i < 10) {
                time = '0' + time;
            }
            times.push(time);
        }
    }
    return times;
  }

  ionViewDidEnter(): void {
     this.translationService.loadTranslation('check_availability', this.translations);
  }

  public dismissModal(data = {}) {
    this.modalController.dismiss(data);
  }

  public isTimeFrameAvailable(from: string) {

    const tmpDateFrom = this.pivotDate;

    const dateFrom = tmpDateFrom.setHours(parseInt(from.split(':')[0]), parseInt(from.split(':')[1]));
    const dateto = new Date(dateFrom.valueOf() + (15 * 60));

    let timeslot = new TimespotslotavailabilityModel();

    for (let i = 0, len = this.timeslostsAvailability.length; i < len; i++) {
        timeslot = this.timeslostsAvailability[i];

        const timeSlotFromValue = timeslot.from.valueOf();
        const timeSlotToValue = timeslot.to.valueOf();

        // if the from - to provided is fully inside an available period, return true.
        if (dateFrom.valueOf() >= timeSlotFromValue && dateFrom.valueOf() <= timeSlotToValue && dateto.valueOf() >= timeSlotFromValue && dateto.valueOf() <= timeSlotToValue) {
            return true;
        }
    }

    return false;
  }
}
