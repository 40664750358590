import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationInterceptor implements HttpInterceptor {

    public refreshTokenIsProcessing = false;

    constructor(private storage: StorageService, private http: HttpClient) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(req.headers.has('Authorization'))
        {
            this.refreshTokenIfNecessary();
        }

        return this.nextWhenReady(req, next);
    }

    private nextWhenReady(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const checkRefreshTokenProcessStatus = () => {
          if(this.refreshTokenIsProcessing === true) {
              window.setTimeout(() => checkRefreshTokenProcessStatus(), 100);
              return;
          }
        };

        checkRefreshTokenProcessStatus();

        const newHeaders = req.headers;
        newHeaders.set('Authorization', 'Bearer ' + this.storage.getBearer());

        const updatedReq = req.clone({
           headers: newHeaders
        });

        return next.handle(updatedReq);
    }

    private refreshTokenIfNecessary() {
        if (
            this.storage.getTokenExpiration() === null
            ||
            (Number(this.storage.getTokenExpiration()) - Math.floor( (Number(Date.now()) / 1000) )) <= (86400 * 30)
        ) {
            this.callOauthToken({
                refresh_token: this.storage.getRefreshToken(),
                grant_type: 'refresh_token',
                client_id: environment.apiClientId,
                client_secret: environment.apiClientSecret
            });
        }
    }

    private callOauthToken(oauthData) {
        this.refreshTokenIsProcessing = true;
        return this.http.post(this.getHost() + '/oauth/token', oauthData).subscribe((response) => {
            this.storage.storeOAuthResponse(response);
            this.refreshTokenIsProcessing = false;
        }, (response) => {
            this.refreshTokenIsProcessing = false;
        });
    }

    private getHost() {
        return environment.apiHost;
    }
}
