import { Injectable } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '../../services/storage.service';

@Injectable({
  providedIn: 'root'
})

export class OrganisationService {

  constructor(
      private api: ApiService,
      private http: HttpClient,
      private storageService: StorageService
  ) { }

  public getOrganisations() {
    return this.http.get(this.api.getHostVersion() + '/organisations', {headers: this.api.getHeaders()});
  }

  public getCurrentProfile() {
    const organisation = this.storageService.getCurrentOrganisation();

    return this.http.get(this.api.getHostVersion() + '/organisation/' + organisation + '/profile', {headers: this.api.getHeaders()});
  }

  public switchOrganisation(organisationId) {
    this.storageService.setCurrentOrganisation(organisationId);
  }
}
